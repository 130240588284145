<template>
  <div class="px-6 py-3 text-sm text-gray-500 font-medium" :class="[firstItem ? 'lg:pl-8' : '']">
    <slot>{{ content }}</slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TableRowItem",
  props: {
    content: {
      type: [String, Number]
    },
    firstItem: { type: Boolean, default: false }
  }
});
</script>
