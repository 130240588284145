
import { defineComponent } from "vue";
import TableHeaderItem from "./TableHeaderItem.vue";

export default defineComponent({
  name: "TableBase",
  components: { TableHeaderItem },
  props: { selectable: { type: Boolean, default: false } },
  setup() {
    return {};
  }
});
