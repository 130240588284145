<template>
  <TableBase :selectable="selectable">
    <template #header>
      <TableHeaderItem
        class="text-left hidden md:table-cell"
        :class="[selectable ? 'md:col-span-2' : 'md:col-span-3']"
        :text="'Details'"
        :firstItem="!selectable"
      />
      <TableHeaderItem
        class="text-left md:col-span-5"
        :class="[selectable ? 'col-span-6' : 'col-span-10']"
        :text="'Demand'"
      />
      <TableHeaderItem
        class="text-left hidden md:table-cell"
        :class="[selectable ? 'md:col-span-2' : 'md:col-span-3']"
        :text="'Recurrence'"
      />
      <TableHeaderItem class="col-span-2 md:col-span-1" />
    </template>
    <template #body>
      <DemandTemplateTableRow
        v-for="demandTemplate in demandTemplates"
        :key="demandTemplate.id"
        :demandTemplate="demandTemplate"
        :selectable="selectable"
        :selected="selectedIdList.includes(demandTemplate.id)"
        @selected="onSelected"
      />
    </template>
  </TableBase>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
// components
import DemandTemplateTableRow from "./DemandTemplateTableRow.vue";
import TableBase from "@/components/table/TableBase.vue";
import TableHeaderItem from "@/components/table/TableHeaderItem.vue";
// other
import { DemandTemplate } from "@/graphql/types";

export default defineComponent({
  name: "DemandTemplateTable",
  components: {
    DemandTemplateTableRow,
    TableBase,
    TableHeaderItem
  },
  props: {
    workAreaId: {
      type: String,
      requried: true
    },
    selectable: { type: Boolean, default: false },
    demandTemplates: { type: Object as PropType<DemandTemplate[]>, required: true },
    selectedIdList: {
      required: false,
      default: () => [] // error if instead "type: Array"
    }
  },
  emits: ["selected"],
  setup(props, context) {
    // pass through event
    const onSelected = (demandTemplateId: string) => {
      context.emit("selected", demandTemplateId);
    };

    return { onSelected };
  }
});
</script>
