<template>
  <FormModal
    :visible="visible"
    :header="'Create demand template'"
    :size="'2xl'"
    :fullHeigth="true"
    @close="$emit('close')"
  >
    <template #content>
      <DemandTemplateForm @close="$emit('close')" :workAreaId="workAreaId" />
    </template>
  </FormModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// components
import FormModal from "@/components/modals/FormModal.vue";
import DemandTemplateForm from "@/features/demandTemplates/DemandTemplateForm.vue";

export default defineComponent({
  name: "DemandTemplate",
  components: {
    FormModal,
    DemandTemplateForm
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    workAreaId: {
      type: String,
      required: true
    }
  },
  emits: { close: null },
  setup() {
    return {};
  }
});
</script>
