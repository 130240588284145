
import { defineComponent, computed } from "vue";
// components
import DemandInput from "./DemandInput.vue";
import DemandChart from "@/features/demand/DemandChart.vue";
// other
import useDemand, { DemandFormInput } from "@/features/demand/useDemand";

export default defineComponent({
  name: "DemandForm",
  components: {
    DemandInput,
    DemandChart
  },
  props: {
    demandList: {
      type: Array as () => DemandFormInput[],
      required: true
    },
    displaySave: { type: Boolean, default: false },
    addDemandLoading: { type: Boolean, default: false },
    saveDemandLoading: { type: Boolean, default: false }
  },
  emits: ["add-demand", "remove-demand", "save-demand", "demand-altered"],

  setup(props, context) {
    const { timeIntervals, createDemandArrayFromDemandFormInputList } = useDemand();

    const demandArray = computed(() => createDemandArrayFromDemandFormInputList(props.demandList));

    const onAddDemand = () => {
      context.emit("add-demand");
    };

    const onSaveDemand = (demand: DemandFormInput) => {
      context.emit("save-demand", demand);
    };

    const onDeleteDemand = (demand: DemandFormInput) => {
      context.emit("remove-demand", demand);
    };

    const onDemandAltered = (demand: DemandFormInput) => {
      context.emit("demand-altered", demand);
    };

    return {
      onAddDemand,
      onSaveDemand,
      onDeleteDemand,
      onDemandAltered,
      timeIntervals,
      demandArray
    };
  }
});
