<template>
  <!-- Close button -->
  <div class="absolute top-0 right-0 pt-4 pr-4" :class="[autoHide ? 'hidden sm:block' : 'block']">
    <button
      @click="$emit('click')"
      type="button"
      class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      <span class="sr-only">Close</span>
      <!-- Heroicon name: x -->
      <svg
        class="h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CloseButton",
  props: {
    autoHide: {
      type: Boolean,
      default: true
    }
  },
  emits: ["click"]
});
</script>
