
import { defineComponent } from "vue";
// components
import FormModal from "@/components/modals/FormModal.vue";
import DemandTemplateForm from "@/features/demandTemplates/DemandTemplateForm.vue";

export default defineComponent({
  name: "DemandTemplate",
  components: {
    FormModal,
    DemandTemplateForm
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    workAreaId: {
      type: String,
      required: true
    }
  },
  emits: { close: null },
  setup() {
    return {};
  }
});
