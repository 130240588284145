
import { defineComponent, onUnmounted, computed, onMounted, onUpdated, watch, ref } from "vue";
// components
import useSelectMenu, { SelectMenuItem } from "@/components/selectMenu/useSelectMenu";
import SelectMenuButton from "@/components/selectMenu/SelectMenuButton.vue";
// other
import { createRandomPrefixedId } from "@/utils/globalHelpers";
import { createPopper } from "@popperjs/core";

export default defineComponent({
  name: "SelectMenu",
  components: {
    SelectMenuButton
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    selectMenuItems: {
      type: Array as () => SelectMenuItem[],
      default: () => []
    },
    selectedIndex: {
      type: Number,
      default: 0
    },
    width: {
      type: String,
      required: true
    },
    showButtonIcon: {
      type: Boolean,
      default: true
    },
    variant: {
      type: String,
      default: "button",
      validator: (prop: string) => ["button", "time"].includes(prop)
    },
    displayError: {
      type: Boolean,
      default: false
    }
  },
  emits: ["selected"],
  setup(props, context) {
    // id for click outside directive
    const menuId = createRandomPrefixedId("selectMenu");
    const menuListId = createRandomPrefixedId("selectMenuList");
    const menuButtonId = createRandomPrefixedId("selectMenuButton");

    const sizeClasses = computed(() => `w-${props.width}`);

    const {
      state,
      setHighlightedById,
      clearHighlighted,
      isHighlighted,
      isSelected,
      onSelectItem,
      onToggleMenu,
      onOpenMenu,
      onCloseMenu,
      onMouseEnter,
      onMouseLeave,
      menuVisible,
      selected,
      resetselectMenuInputValue,
      removeEventListeners
    } = useSelectMenu(
      props.selectMenuItems,
      props.selectMenuItems[props.selectedIndex],
      menuListId,
      props.variant as "button" | "time",
      context
    );

    // create  popover for select menu item list
    const createSelectMenuPopover = () => {
      const button = document.getElementById(menuButtonId);
      const list = document.getElementById(menuListId);
      if (button && list) {
        createPopper(button, list, {
          placement: "bottom-start",
          strategy: "fixed",
          modifiers: [
            // no flipping if overflow
            {
              name: "flip",
              enabled: false
            },
            // ignore distance to any other elements that might push the popover
            {
              name: "preventOverflow",
              options: {
                mainAxis: false
              }
            },
            // small offset to select menu
            {
              name: "offset",
              options: {
                offset: [0, 4]
              }
            }
          ]
        });
      }
    };

    // close menu on scroll event
    let menu: HTMLElement | null = null;
    const getMenuYPos = () => menu?.getBoundingClientRect().top;
    const buttonPosition = ref(getMenuYPos());
    // close if position of the element has changed
    const closeMenuOnScroll = () => {
      const position = getMenuYPos();
      if (position !== buttonPosition.value) onCloseMenu();
      buttonPosition.value = getMenuYPos();
    };

    onMounted(() => {
      // create popover
      createSelectMenuPopover();

      // watch scroll to close menu if scrolled outside view
      watch(menuVisible, curr => {
        menu = document.getElementById(menuId);
        // only close menu after scrolled for longer than 0.2 seconds, to avoid closing on mobile auto focus
        setTimeout(() => {
          buttonPosition.value = getMenuYPos();
          if (curr === true) document.addEventListener("scroll", closeMenuOnScroll, true);
        }, 200);

        if (curr === false) document.removeEventListener("scroll", closeMenuOnScroll, true);
      });
    });

    onUnmounted(() => {
      removeEventListeners();
      document.removeEventListener("scroll", closeMenuOnScroll, true);
    });

    return {
      state,
      sizeClasses,
      menuId,
      menuListId,
      menuButtonId,
      setHighlightedById,
      clearHighlighted,
      isHighlighted,
      isSelected,
      onSelectItem,
      onToggleMenu,
      resetselectMenuInputValue,
      onOpenMenu,
      onCloseMenu,
      onMouseEnter,
      onMouseLeave,
      menuVisible,
      selected
    };
  }
});
