<template>
  <div class="">
    <div class="w-24">
      <SelectMenu
        :label="label"
        :selectMenuItems="selectMenuItems"
        :selectedIndex="defaultIndex"
        :variant="'time'"
        :displayError="displayError"
        :width="'24'"
        @selected="onSelectedTime"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { SelectMenuItem } from "@/components/selectMenu/useSelectMenu";
import SelectMenu from "@/components/selectMenu/SelectMenu.vue";
import { addIdsToStringArray } from "@/utils/arrayHelpers";
import { createTimeIntervals } from "@/utils/useTimeIntervals";
import { parseTimeString, formatDate, addDate } from "@/utils/dateHelpers";

export default defineComponent({
  name: "TimePicker",
  components: { SelectMenu },
  props: {
    label: {
      type: String,
      default: ""
    },
    displayError: {
      type: Boolean,
      default: false
    },
    startTime: {
      type: String,
      default: "06:00"
    },
    endTime: {
      type: String,
      default: "17:00"
    },
    minuteSteps: {
      type: Number,
      default: 15
    },
    initialTime: {
      type: String,
      default: "00:00"
    },
    addClosingInterval: {
      type: Boolean,
      default: false
    }
  },
  emits: ["time-selected"],
  setup(props, context) {
    const startDateTime = parseTimeString(props.startTime);
    let endDateTime = parseTimeString(props.endTime);

    if (props.addClosingInterval === true) {
      endDateTime = addDate(endDateTime, { minutes: props.minuteSteps });
    }

    // create array of time strings from time intervals
    const time = createTimeIntervals(startDateTime, endDateTime, props.minuteSteps).map(interval =>
      formatDate(interval, "HH:mm")
    );

    // create select menu items
    const selectMenuItems = addIdsToStringArray(time);

    // find index of default value, if value is not found, use first index
    const defaultIndex = Math.max(0, time.indexOf(props.initialTime));

    // set hours and emit time
    const onSelectedTime = (time: SelectMenuItem) => {
      context.emit("time-selected", time.value);
    };

    // initially emit default time
    onMounted(() => context.emit("time-selected", time[defaultIndex]));

    return { selectMenuItems, onSelectedTime, defaultIndex };
  }
});
</script>

<style></style>
