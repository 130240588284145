<template>
  <div class="flex">
    <div :class="[labelPosition === 'right' ? 'items-start' : 'flex-col items-center']" class="flex">
      <div class="h-5 flex items-center">
        <input
          :id="name"
          :name="name"
          type="checkbox"
          v-bind="$attrs"
          :checked="modelValue"
          @input="$emit('update:modelValue', $event.target.checked)"
          class="focus:ring-blue-500 h-4 w-4 text-blue-600 active:text-blue-700 border-gray-300 rounded"
        />
      </div>
      <div :class="[labelPosition === 'right' ? 'ml-3' : 'mt-1']" class="text-sm">
        <label :for="name" class="font-medium text-gray-700"
          ><slot name="label">{{ label }}</slot></label
        >
        <p class="text-gray-500">
          <slot name="caption">{{ caption }}</slot>
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseCheckbox",
  props: {
    label: {
      type: String,
      default: ""
    },
    caption: {
      type: String,
      default: ""
    },
    // only used for v-model binding, no actual prop
    modelValue: {
      type: [Boolean],
      required: true
    },
    name: {
      type: String,
      required: true
    },
    labelPosition: {
      type: String,
      validator: (prop: string) => ["right", "bottom"].includes(prop),
      default: "right"
    }
  },
  setup() {
    return {};
  }
});
</script>
