<template>
  <div
    class="cursor-pointer group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
    role="menuitem"
  >
    <slot>
      <div class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500">
        <slot name="icon"></slot>
      </div>
      {{ text }}
    </slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TableRowMenuItem",
  components: {},
  props: {
    text: {
      type: String
    }
  },
  setup() {
    return {};
  }
});
</script>

<style></style>
