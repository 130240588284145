<template>
  <button
    type="button"
    :class="sizeClasses"
    class="flex justify-left bg-white relative border border-gray-300 rounded-md shadow-sm pl-3 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
  >
    <slot></slot>
    <span v-if="showIcon" class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
      <!-- Heroicon name: selector -->
      <svg
        class="h-5 w-5 text-gray-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </span>
  </button>
</template>

<script>
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "SelectMenuButton",
  props: {
    showIcon: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const sizeClasses = computed(() => {
      if (props.showIcon === true) return "pr-10";
      else return "pr-3";
    });

    return { sizeClasses };
  }
});
</script>

<style></style>
