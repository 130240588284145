import { reactive, computed, ref } from "vue";

import useDemand, { DemandFormInput } from "@/features/demand/useDemand";
import useRecurrencePattern, { RecurrencePatternFormInput } from "@/features/recurrencePattern/useRecurrencePattern";

export interface DemandTemplateInput {
  name: string;
  description: string;
  demandList: DemandFormInput[];
  recurrencePatternList: RecurrencePatternFormInput[];
}

// demand template hook
export default function useDemandTemplateForm() {
  /**
   * Initialize data
   */
  const { createDemandFormInput, timeIntervals } = useDemand();
  const { createRecurrencePatternFormInput } = useRecurrencePattern();

  // create initial demand
  const initialDemand = createDemandFormInput();
  // create initial recurrence pattern
  const initialRecurrencePattern = createRecurrencePatternFormInput();

  // initialize demand template state
  const demandTemplateInput: DemandTemplateInput = reactive({
    name: "",
    description: "",
    demandList: [initialDemand],
    recurrencePatternList: [initialRecurrencePattern]
  });

  /**
   * Demand
   */

  const onAddDemand = () => {
    const demandFormInput = createDemandFormInput();
    demandTemplateInput.demandList = [...demandTemplateInput.demandList, demandFormInput];
  };

  // add demand to list
  const onRemoveDemand = (demandId: string) => {
    demandTemplateInput.demandList = demandTemplateInput.demandList.filter(x => x.id !== demandId);
  };

  // update demand input
  const onDemandAltered = (demand: DemandFormInput) => {
    //  replace demand in demand list
    const index = demandTemplateInput.demandList.findIndex(d => d.id === demand.id);
    if (index !== -1) demandTemplateInput.demandList[index] = demand;
  };

  /**
   * Recurrence pattern related activities
   */

  // update recurrence pattern
  const onSelectPattern = (pattern: RecurrencePatternFormInput) => {
    //  replace pattern in pattern list
    const index = demandTemplateInput.recurrencePatternList.findIndex(p => p.id === pattern.id);
    if (index !== -1) demandTemplateInput.recurrencePatternList[index] = pattern;
  };

  return {
    demandTemplateInput,
    onAddDemand,
    onRemoveDemand,
    onDemandAltered,
    demandList: computed(() => demandTemplateInput.demandList),
    recurrencePatternList: computed(() => demandTemplateInput.recurrencePatternList),
    onSelectPattern,
    // validate template, check if there are invalid demands
    demandTemplateFormHasError: computed(() => demandTemplateInput.demandList.some(demand => demand.hasError === true)),
    timeIntervals
  };
}
