
import { defineComponent } from "vue";

export default defineComponent({
  name: "TableRowMenuItem",
  components: {},
  props: {
    text: {
      type: String
    }
  },
  setup() {
    return {};
  }
});
