<template>
  <TableRow :selectable="selectable" :selected="selected" @selected="onClick" class="grid-cols-12">
    <TableRowItem
      class="hidden md:table-cell cursor-pointer"
      :class="[selectable ? 'md:col-span-2' : 'md:col-span-3']"
      :firstItem="!selectable"
      @click="onClick"
    >
      <div class="lg:pl-2 flex flex-col space-y-2">
        <p class="mt-1 text-sm text-gray-900 truncate">
          {{ demandTemplate.name }}
        </p>
        <p class="text-sm text-gray-500 line-clamp-4">
          <span v-if="demandTemplate.name == '' && demandTemplate.description == ''">no description</span>
          {{ demandTemplate.description }}
        </p>
      </div>
    </TableRowItem>

    <TableRowItem class="md:col-span-5" :class="[selectable ? 'col-span-6' : 'col-span-10']">
      <div class="h-28">
        <DemandChart class="p-2 h-28" :demandArray="demandArray" :timeIntervals="timeIntervals" />
      </div>
    </TableRowItem>
    <TableRowItem class="hidden md:table-cell" :class="[selectable ? 'md:col-span-2' : 'md:col-span-3']">
      <p v-for="pattern in demandTemplate.recurrence_patterns" :key="pattern.id" class="mt-1 text-sm text-gray-700">
        {{ recurrencePatternToText(pattern) }}
      </p>
    </TableRowItem>
    <TableRowItem class="col-span-1 md:col-span-1 flex justify-center"
      ><DemandTemplateTableRowMenu :demandTemplate="demandTemplate"
    /></TableRowItem>
  </TableRow>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "vue";
// components
import DemandTemplateTableRowMenu from "./DemandTemplateTableRowMenu.vue";
import TableRow from "@/components/table/TableRow.vue";
import TableRowItem from "@/components/table/TableRowItem.vue";
import DemandChart from "@/features/demand/DemandChart.vue";
// other
import { DemandTemplate } from "@/graphql/types";
import { useRouter } from "vue-router";
import { cleanUuid } from "@/utils/globalHelpers";
import useDemand, { createDemandFormInputFromDemandParent } from "@/features/demand/useDemand";
import { recurrencePatternToText } from "@/features/recurrencePattern/useRecurrencePattern";

export default defineComponent({
  name: "DemandTemplateTableRow",
  components: {
    DemandTemplateTableRowMenu,
    TableRow,
    TableRowItem,
    DemandChart
  },
  props: {
    demandTemplate: { type: Object as PropType<DemandTemplate>, required: true },
    selectable: { type: Boolean, default: false },
    selected: { type: Boolean, default: false }
  },
  setup(props, context) {
    const router = useRouter();

    const onShowDetails = () => {
      const demandTemplateId = cleanUuid(props.demandTemplate.id);
      const workAreaId = cleanUuid(props.demandTemplate.work_area_id);

      router.push({
        name: "demandTemplateDetails",
        params: { workAreaId: workAreaId, demandTemplateId: demandTemplateId }
      });
    };

    /**
     * Demand array for chart
     */
    const { timeIntervals, createDemandArrayFromDemandFormInputList } = useDemand();
    const demandArray = computed(() => {
      // create convert demand parents to form input
      const demandFormInputList = props.demandTemplate.demand_parents.map(demand => {
        return createDemandFormInputFromDemandParent(demand);
      });
      // create demand array
      return createDemandArrayFromDemandFormInputList(demandFormInputList);
    });

    // click details or demand field
    const onClick = () => {
      if (props.selectable) context.emit("selected", props.demandTemplate.id);
      else onShowDetails();
    };

    return { onClick, recurrencePatternToText, timeIntervals, demandArray };
  }
});
</script>
