
import { defineComponent } from "vue";
// components
import TableRowItem from "./TableRowItem.vue";
// other
import { createRandomPrefixedId } from "@/utils/globalHelpers";

export default defineComponent({
  name: "TableRow",
  components: { TableRowItem },
  props: { selectable: { type: Boolean, default: false }, selected: { type: Boolean, default: false } },
  emits: { selected: null },
  setup(props, context) {
    const inputId = createRandomPrefixedId("tableRow");
    const onSelect = () => context.emit("selected");
    return { inputId, onSelect };
  }
});
