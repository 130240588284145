
import { defineComponent } from "vue";

export default defineComponent({
  name: "TableHeaderItem",
  props: {
    text: {
      type: String
    },
    firstItem: { type: Boolean, default: false }
  },
  setup() {
    return {};
  }
});
