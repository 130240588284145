<template>
  <div class="flex flex-col">
    <div class="flex justify-start items-center">
      <TimePicker
        :label="displayLabel ? 'Start time' : ''"
        :displayError="showStartTimeError"
        :startTime="startTime"
        :endTime="endTime"
        :minuteSteps="timeIntervalSize"
        :initialTime="demandInput.startTime"
        @time-selected="onSelectStartTime"
      />
      <TimePicker
        class="ml-6"
        :label="displayLabel ? 'End time' : ''"
        :displayError="showEndTimeError"
        :startTime="startTime"
        :endTime="endTime"
        :minuteSteps="timeIntervalSize"
        :initialTime="demandInput.endTime"
        :addClosingInterval="true"
        @time-selected="onSelectEndTime"
      />
      <NumberStepper
        class="ml-6"
        :initialValue="demandInput.amount"
        :label="displayLabel ? 'Employees' : ''"
        :minValue="0"
        @number-selected="onSelectEmployees"
      />
      <BaseButton
        v-if="displaySave"
        :disabled="!demandInputAltered || showStartTimeError || showEndTimeError"
        :loading="saveDemandLoading"
        class="ml-3"
        :size="'xs'"
        :variant="'default'"
        :rounded="'lg'"
        @btn-click="onSaveDemand"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 512 512">
          <path
            d="M380.93 57.37A32 32 0 00358.3 48H94.22A46.21 46.21 0 0048 94.22v323.56A46.21 46.21 0 0094.22 464h323.56A46.36 46.36 0 00464 417.78V153.7a32 32 0 00-9.37-22.63zM256 416a64 64 0 1164-64 63.92 63.92 0 01-64 64zm48-224H112a16 16 0 01-16-16v-64a16 16 0 0116-16h192a16 16 0 0116 16v64a16 16 0 01-16 16z"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="32"
          />
        </svg>
      </BaseButton>
      <BaseButton
        v-if="displayDelete"
        class="ml-2"
        :variant="'danger'"
        :size="'xs'"
        :rounded="'lg'"
        @btn-click="onDeleteDemand"
        ><svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
          /></svg
      ></BaseButton>
    </div>
    <div v-if="showStartTimeError || showEndTimeError" class="mt-2 sm:text-sm text-red-600">
      <p v-if="showStartTimeError === true">Start time must be before end time.</p>
      <p v-if="showEndTimeError === true">End time must be after start time.</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType } from "vue";
// components
import TimePicker from "@/components/TimePicker.vue";
import NumberStepper from "@/components/NumberStepper.vue";
// other
import { checkTimeBeforeAfter } from "@/utils/dateHelpers";
import useTimeIntervals from "@/utils/useTimeIntervals";
import useDemand, { DemandFormInput } from "./useDemand";

export default defineComponent({
  name: "DemandFormInput",
  components: {
    TimePicker,
    NumberStepper
  },
  props: {
    demand: { type: Object as PropType<DemandFormInput>, required: true },
    displayLabel: { type: Boolean, default: true },
    displayDelete: { type: Boolean, default: false },
    displaySave: { type: Boolean, default: false },
    saveDemandLoading: { type: Boolean, default: false }
  },
  emits: ["altered", "deleted", "saved"],
  setup(props, context) {
    const { startTime, endTime, timeIntervalSize } = useTimeIntervals();

    const { createDemandFormInput } = useDemand();

    const demandInput = ref(createDemandFormInput({ ...props.demand }));
    const showStartTimeError = ref(false);
    const showEndTimeError = ref(false);

    // check if demand input has been altered
    const initialDemand = ref(JSON.parse(JSON.stringify(props.demand)));
    const demandInputAltered = computed(() => {
      // check form attributes
      const startTimeAltered = initialDemand.value.startTime === demandInput.value.startTime;
      const endTimeAltered = initialDemand.value.endTime === demandInput.value.endTime;
      const amountAltered = initialDemand.value.amount === demandInput.value.amount;
      // check if any attribues deviates from initial demand
      const isAltered = [startTimeAltered, endTimeAltered, amountAltered].some(x => x === false);
      return isAltered;
    });

    // select start time
    const onSelectStartTime = (time: string) => {
      // check if start time is before than end time
      demandInput.value.startTime = time;
      if (checkTimeBeforeAfter(time, demandInput.value.endTime, "before")) {
        // remove errors
        showStartTimeError.value = false;
        showEndTimeError.value = false;
        demandInput.value.hasError = false;
        // emit demand
        context.emit("altered", demandInput.value);
      } else {
        // display error
        showStartTimeError.value = true;
        demandInput.value.hasError = true;
      }
    };

    // select end time
    const onSelectEndTime = (time: string) => {
      // check if end time is later than end time
      demandInput.value.endTime = time;
      if (checkTimeBeforeAfter(demandInput.value.endTime, demandInput.value.startTime, "after")) {
        // remove errors
        showEndTimeError.value = false;
        showStartTimeError.value = false;
        demandInput.value.hasError = false;

        // emit demand
        context.emit("altered", demandInput.value);
      } else {
        // display error
        showEndTimeError.value = true;
        demandInput.value.hasError = true;
      }
    };

    // select employee
    const onSelectEmployees = (employeeCount: number) => {
      demandInput.value.amount = employeeCount;
      context.emit("altered", demandInput.value);
    };

    // delete demand
    const onDeleteDemand = () => {
      // emit demand id
      context.emit("deleted", props.demand.id);
    };

    // delete demand
    const onSaveDemand = () => {
      // emit demand id
      context.emit("saved", demandInput.value);
      // reset initial demand
      initialDemand.value = JSON.parse(JSON.stringify(demandInput.value));
    };

    return {
      demandInput,
      demandInputAltered,
      onSelectStartTime,
      onSelectEndTime,
      onSelectEmployees,
      startTime,
      endTime,
      timeIntervalSize,
      showStartTimeError,
      showEndTimeError,
      onDeleteDemand,
      onSaveDemand
    };
  }
});
</script>
