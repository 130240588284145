
import { defineComponent } from "vue";
// components
import DemandForm from "@/features/demand/DemandForm.vue";
import RecurrencePatternInput from "@/features/recurrencePattern/RecurrencePatternInput.vue";
// store
import useDemandTemplateStore from "@/store/useDemandTemplateStore";
// other
import useDemandTemplateForm from "./useDemandTemplateForm";

export default defineComponent({
  name: "DemandTemplate",
  components: {
    DemandForm,
    RecurrencePatternInput
  },
  props: {
    workAreaId: {
      type: String,
      required: true
    }
  },
  emits: ["close"],
  setup(props, context) {
    // store
    const { onCreateDemandTemplate, onCreateDemandTemplateSuccess, loading, error } = useDemandTemplateStore({
      workAreaId: props.workAreaId
    });

    // demand template form
    const {
      demandTemplateInput,
      onAddDemand,
      onRemoveDemand,
      onDemandAltered,
      demandList,
      recurrencePatternList,
      onSelectPattern,
      demandTemplateFormHasError,
      timeIntervals
    } = useDemandTemplateForm();

    // Validate and create demandTemplate
    const onSubmit = () => {
      // validate if any demand has errors
      if (demandTemplateFormHasError.value === true) return;
      // create demand template
      onCreateDemandTemplate(
        demandTemplateInput.name,
        demandTemplateInput.description,
        demandList.value,
        recurrencePatternList.value
      );
    };

    // close form and reset data
    const onClose = () => {
      context.emit("close");
    };

    // cleanup after successful action
    onCreateDemandTemplateSuccess(() => {
      console.info("Demand template successfully created");
      onClose();
    });

    return {
      onSubmit,
      onClose,
      demandTemplateFormHasError,
      loading,
      error,
      onSelectPattern,
      recurrencePatternList,
      demandList,
      onAddDemand,
      onRemoveDemand,
      onDemandAltered,
      timeIntervals
    };
  }
});
