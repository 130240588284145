<template>
  <div
    v-click-outside="{
      excludedIds: [stepperId],
      handler: resetStepper
    }"
  >
    <label v-if="label" class="block text-sm font-medium text-gray-700">
      <div class="mb-1">{{ label }}</div>
    </label>

    <div class="inline-flex z-0 shadow-sm rounded-md">
      <button @click="onDecrement" type="button" :class="buttonClasses" class="-mr-px rounded-l-md">
        <!-- Heroicon name: chevron-left -->
        <svg
          class="ml-1 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <input
        class="w-12 block text-center sm:text-sm border-gray-300 focus:z-10 focus:ring-blue-500 focus:border-blue-500 "
        type="text"
        inputmode="numeric"
        maxLength="2"
        v-model="stepperValue"
      />

      <button @click="onIncrement" type="button" :class="buttonClasses" class="-ml-px rounded-r-md">
        <!-- Heroicon name: chevron-right -->
        <svg
          class="ml-1 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { createRandomPrefixedId } from "@/utils/globalHelpers";

export default defineComponent({
  name: "NumberStepper",
  props: {
    label: {
      type: String,
      default: ""
    },
    initialValue: {
      type: Number,
      default: 0
    },
    minValue: {
      type: Number,
      default: -99
    },
    maxValue: {
      type: Number,
      default: 99
    },
    excludedValues: {
      type: Array as () => number[],
      default: () => []
    }
  },
  emits: ["number-selected"],
  setup(props, context) {
    const stepperValue = ref(props.initialValue);
    const stepperId = createRandomPrefixedId("numberStepper");

    // check if min or max values of stepper are in exclude list
    if (props.excludedValues.includes(props.maxValue) || props.excludedValues.includes(props.minValue)) {
      console.error("Min or max values of number stepper must not be excluded!");
    }

    // increment number
    const onIncrement = () => {
      if (stepperValue.value < props.maxValue) {
        stepperValue.value++;
        // check if new value is excluded. if so, increment again
        if (props.excludedValues.includes(stepperValue.value)) onIncrement();
      }
    };

    // decrement number
    const onDecrement = () => {
      if (stepperValue.value > props.minValue) {
        stepperValue.value--;
        // check if new value is excluded. if so, decrement again
        if (props.excludedValues.includes(stepperValue.value)) onDecrement();
      }
    };

    // reset stepper to initial value, used for click outside
    const resetStepper = () => {
      if (typeof stepperValue.value === "string" && stepperValue.value === "") stepperValue.value = props.initialValue;
    };

    // handle input strings
    watch(stepperValue, curr => {
      // ignore empty strings
      if (typeof curr === "string" && curr === "") return;
      if (typeof curr === "string") {
        // handle input of strings
        // convert to number
        const input = parseInt(curr);
        // if input is number, set check range, otherwise set to initial value
        if (isNaN(input) === false) {
          // check max and min values
          if (stepperValue.value <= props.maxValue && stepperValue.value >= props.minValue) stepperValue.value = input;
          else stepperValue.value = props.initialValue;
          // set to initial value if no number is entered
        } else stepperValue.value = props.initialValue;
      }
      // emit selected number
      context.emit("number-selected", stepperValue.value);
    });

    const buttonClasses =
      "w-8 inline-flex items-center py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 active:bg-gray-100 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500";

    return {
      stepperValue,
      resetStepper,
      onIncrement,
      onDecrement,
      stepperId,
      buttonClasses
    };
  }
});
</script>
