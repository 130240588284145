<template>
  <div class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
    <span :class="[firstItem ? 'lg:pl-2' : '']">
      <slot>
        {{ text }}
      </slot>
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TableHeaderItem",
  props: {
    text: {
      type: String
    },
    firstItem: { type: Boolean, default: false }
  },
  setup() {
    return {};
  }
});
</script>

<style></style>
