<template>
  <div :class="sizeClasses">
    <label v-if="label" class="block text-sm font-medium text-gray-700 mb-1">
      {{ label }}
    </label>
    <div
      :id="menuId"
      class="relative"
      :class="sizeClasses"
      v-click-outside="{
        excludedIds: [menuId],
        handler: onCloseMenu
      }"
    >
      <!-- Standard button selector variant -->
      <SelectMenuButton
        :id="menuButtonId"
        v-if="variant === 'button'"
        @click="onToggleMenu()"
        :class="sizeClasses"
        :showIcon="showButtonIcon"
      >
        <slot name="display-button" :selectedItem="selected"></slot>
      </SelectMenuButton>

      <!-- Time selector variant with input field-->
      <div class="relative">
        <input
          v-if="variant === 'time'"
          @click="onOpenMenu"
          @keydown="onOpenMenu"
          type="text"
          maxlength="5"
          :id="menuButtonId"
          v-model="state.selectMenuInputValue"
          class="shadow-sm block w-full sm:text-sm rounded-md"
          :class="[
            displayError
              ? 'border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500'
              : 'border-gray-300 focus:border-blue-500 focus:ring-blue-500'
          ]"
        />

        <span class="absolute inset-y-0 top-0 right-0 flex items-center pr-2 pointer-events-none">
          <!-- Heroicon name: clock -->
          <svg
            v-if="menuVisible === false"
            :class="[displayError ? 'text-red-500' : 'text-gray-400']"
            class="h-5 w-5 "
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <!-- Heroicon name: x -->
          <svg
            v-if="menuVisible === true"
            class="h-5 w-5 pointer-events-auto cursor-pointer"
            :class="[displayError ? 'text-red-500' : 'text-gray-400']"
            @click="resetselectMenuInputValue(menuId)"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </span>
      </div>

      <!-- <transition
        enter-active-class=""
        enter-from-class=""
        enter-to-class=""
        leave-active-class="transition ease-in duration-75"
        leave-from-class="opacity-50"
        leave-to-class="opacity-0"
      > -->
      <div :id="menuListId" v-show="menuVisible" class="fixed rounded-md bg-white shadow-lg z-30" :class="sizeClasses">
        <ul
          class="max-h-48 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto overscroll-contain focus:outline-none sm:text-sm"
        >
          <li
            :id="item.id"
            v-for="item in state.options"
            :key="item.id"
            class="flex justify-left cursor-default select-none relative py-2 pl-3 pr-9"
            :class="[isHighlighted(item.id) ? 'text-white bg-blue-500' : 'text-gray-900']"
            @click="onSelectItem(item)"
            @mouseenter="onMouseEnter(item.id)"
            @mouseleave="onMouseLeave"
          >
            <slot
              name="display-list"
              :isSelected="isSelected(item.id)"
              :isHighlighted="isHighlighted(item.id)"
              :item="item"
              >{{ item.value }}</slot
            >
          </li>
        </ul>
      </div>
      <!-- </transition> -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onUnmounted, computed, onMounted, onUpdated, watch, ref } from "vue";
// components
import useSelectMenu, { SelectMenuItem } from "@/components/selectMenu/useSelectMenu";
import SelectMenuButton from "@/components/selectMenu/SelectMenuButton.vue";
// other
import { createRandomPrefixedId } from "@/utils/globalHelpers";
import { createPopper } from "@popperjs/core";

export default defineComponent({
  name: "SelectMenu",
  components: {
    SelectMenuButton
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    selectMenuItems: {
      type: Array as () => SelectMenuItem[],
      default: () => []
    },
    selectedIndex: {
      type: Number,
      default: 0
    },
    width: {
      type: String,
      required: true
    },
    showButtonIcon: {
      type: Boolean,
      default: true
    },
    variant: {
      type: String,
      default: "button",
      validator: (prop: string) => ["button", "time"].includes(prop)
    },
    displayError: {
      type: Boolean,
      default: false
    }
  },
  emits: ["selected"],
  setup(props, context) {
    // id for click outside directive
    const menuId = createRandomPrefixedId("selectMenu");
    const menuListId = createRandomPrefixedId("selectMenuList");
    const menuButtonId = createRandomPrefixedId("selectMenuButton");

    const sizeClasses = computed(() => `w-${props.width}`);

    const {
      state,
      setHighlightedById,
      clearHighlighted,
      isHighlighted,
      isSelected,
      onSelectItem,
      onToggleMenu,
      onOpenMenu,
      onCloseMenu,
      onMouseEnter,
      onMouseLeave,
      menuVisible,
      selected,
      resetselectMenuInputValue,
      removeEventListeners
    } = useSelectMenu(
      props.selectMenuItems,
      props.selectMenuItems[props.selectedIndex],
      menuListId,
      props.variant as "button" | "time",
      context
    );

    // create  popover for select menu item list
    const createSelectMenuPopover = () => {
      const button = document.getElementById(menuButtonId);
      const list = document.getElementById(menuListId);
      if (button && list) {
        createPopper(button, list, {
          placement: "bottom-start",
          strategy: "fixed",
          modifiers: [
            // no flipping if overflow
            {
              name: "flip",
              enabled: false
            },
            // ignore distance to any other elements that might push the popover
            {
              name: "preventOverflow",
              options: {
                mainAxis: false
              }
            },
            // small offset to select menu
            {
              name: "offset",
              options: {
                offset: [0, 4]
              }
            }
          ]
        });
      }
    };

    // close menu on scroll event
    let menu: HTMLElement | null = null;
    const getMenuYPos = () => menu?.getBoundingClientRect().top;
    const buttonPosition = ref(getMenuYPos());
    // close if position of the element has changed
    const closeMenuOnScroll = () => {
      const position = getMenuYPos();
      if (position !== buttonPosition.value) onCloseMenu();
      buttonPosition.value = getMenuYPos();
    };

    onMounted(() => {
      // create popover
      createSelectMenuPopover();

      // watch scroll to close menu if scrolled outside view
      watch(menuVisible, curr => {
        menu = document.getElementById(menuId);
        // only close menu after scrolled for longer than 0.2 seconds, to avoid closing on mobile auto focus
        setTimeout(() => {
          buttonPosition.value = getMenuYPos();
          if (curr === true) document.addEventListener("scroll", closeMenuOnScroll, true);
        }, 200);

        if (curr === false) document.removeEventListener("scroll", closeMenuOnScroll, true);
      });
    });

    onUnmounted(() => {
      removeEventListeners();
      document.removeEventListener("scroll", closeMenuOnScroll, true);
    });

    return {
      state,
      sizeClasses,
      menuId,
      menuListId,
      menuButtonId,
      setHighlightedById,
      clearHighlighted,
      isHighlighted,
      isSelected,
      onSelectItem,
      onToggleMenu,
      resetselectMenuInputValue,
      onOpenMenu,
      onCloseMenu,
      onMouseEnter,
      onMouseLeave,
      menuVisible,
      selected
    };
  }
});
</script>

<style></style>
