
import { defineComponent } from "vue";

export default defineComponent({
  name: "TableRowItem",
  props: {
    content: {
      type: [String, Number]
    },
    firstItem: { type: Boolean, default: false }
  }
});
