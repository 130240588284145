
import { defineComponent, computed } from "vue";
// components
import CloseButton from "../buttons/CloseButton.vue";
// other
import { createRandomPrefixedId } from "@/utils/globalHelpers";

export default defineComponent({
  components: { CloseButton },
  name: "FormModal",
  props: {
    header: {
      type: String
    },
    size: {
      type: String,
      default: "lg",
      validator: (prop: string) => ["sm", "lg", "xl", "2xl", "3xl", "4xl", "5xl", "6xl", "7xl", "full"].includes(prop)
    },
    fullHeigth: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      required: true
    },
    clickOutside: {
      type: Boolean,
      required: false
    }
  },
  emits: {
    close: null
  },
  setup(props, context) {
    // id of modal background and form
    const modalId = createRandomPrefixedId("formModal");

    const onClose = () => {
      context.emit("close");
    };

    const onClickOutside = () => {
      if (props.clickOutside == true) context.emit("close");
    };

    const sizeClasses = computed(() => `sm:max-w-${props.size}`);

    return {
      onClose,
      onClickOutside,
      sizeClasses,
      modalId
    };
  }
});
