
import { defineComponent, computed, ref } from "vue";
// components
import DetailsSection from "@/components/details/DetailsSection.vue";
import DemandTemplateTable from "@/features/demandTemplates/table/DemandTemplateTable.vue";
import DemandTemplateFormModal from "@/features/demandTemplates/DemandTemplateFormModal.vue";
// Store
import useDemandTemplateStore from "@/store/useDemandTemplateStore";
// other
import { useRoute } from "vue-router";
import { repairUuid } from "@/utils/globalHelpers";

export default defineComponent({
  name: "WorkAreaDetailsPageDemand",
  components: {
    DetailsSection,
    DemandTemplateTable,
    DemandTemplateFormModal
  },
  setup() {
    const {
      params: { workAreaId: workAreaUrlId }
    } = useRoute();
    const workAreaId = computed(() => repairUuid(workAreaUrlId));

    // demand
    const { demandTemplates } = useDemandTemplateStore({ allDemandTemplates: true, workAreaId: workAreaId.value });

    const formVisible = ref(false);

    return { workAreaId, formVisible, demandTemplates };
  }
});
