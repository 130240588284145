<template>
  <div class="flex flex-col w-full overflow-hidden">
    <div class="h-36">
      <DemandChart class="p-2 h-36" :demandArray="demandArray" :timeIntervals="timeIntervals" />
    </div>
    <div class="flex -mb-1">
      <BaseLabel class="" :text="'Start time'" />
      <BaseLabel class="ml-14" :text="'End time'" />
      <BaseLabel :style="{ 'margin-left': '3.8rem' }" :text="'Employees'" />
    </div>
    <ul class="overflow-auto divide-y divide-gray-200">
      <li class="py-2 flex items-center" v-for="demand in demandList" :key="demand.id">
        <DemandInput
          :demand="demand"
          :displayLabel="false"
          :displayDelete="demandList.length > 1"
          :displaySave="displaySave"
          :saveDemandLoading="saveDemandLoading"
          @altered="onDemandAltered"
          @deleted="onDeleteDemand"
          @saved="onSaveDemand"
        />
      </li>
    </ul>
    <BaseButton
      class="mt-1"
      form="demand-template"
      :variant="'secondary'"
      :rounded="'lg'"
      @btn-click="onAddDemand"
      :loading="addDemandLoading"
      >Add demand</BaseButton
    >
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
// components
import DemandInput from "./DemandInput.vue";
import DemandChart from "@/features/demand/DemandChart.vue";
// other
import useDemand, { DemandFormInput } from "@/features/demand/useDemand";

export default defineComponent({
  name: "DemandForm",
  components: {
    DemandInput,
    DemandChart
  },
  props: {
    demandList: {
      type: Array as () => DemandFormInput[],
      required: true
    },
    displaySave: { type: Boolean, default: false },
    addDemandLoading: { type: Boolean, default: false },
    saveDemandLoading: { type: Boolean, default: false }
  },
  emits: ["add-demand", "remove-demand", "save-demand", "demand-altered"],

  setup(props, context) {
    const { timeIntervals, createDemandArrayFromDemandFormInputList } = useDemand();

    const demandArray = computed(() => createDemandArrayFromDemandFormInputList(props.demandList));

    const onAddDemand = () => {
      context.emit("add-demand");
    };

    const onSaveDemand = (demand: DemandFormInput) => {
      context.emit("save-demand", demand);
    };

    const onDeleteDemand = (demand: DemandFormInput) => {
      context.emit("remove-demand", demand);
    };

    const onDemandAltered = (demand: DemandFormInput) => {
      context.emit("demand-altered", demand);
    };

    return {
      onAddDemand,
      onSaveDemand,
      onDeleteDemand,
      onDemandAltered,
      timeIntervals,
      demandArray
    };
  }
});
</script>
