<template>
  <div class="flex flex-col flex-1 overflow-hidden shadow-sm border rounded-md">
    <div class="grid grid-cols-12 bg-gray-50 border-b border-gray-200">
      <TableHeaderItem
        v-if="selectable"
        class="col-span-4 md:col-span-2 text-center"
        :text="'Selected'"
        :firstItem="selectable"
      />
      <slot name="header"></slot>
    </div>
    <div class="flex flex-1 overflow-hidden">
      <div class="bg-white gird grid-cols-12 w-full overflow-x-hidden overflow-y-auto">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TableHeaderItem from "./TableHeaderItem.vue";

export default defineComponent({
  name: "TableBase",
  components: { TableHeaderItem },
  props: { selectable: { type: Boolean, default: false } },
  setup() {
    return {};
  }
});
</script>

<style></style>
