
import { defineComponent, PropType } from "vue";
import RecurrencePatternWeekdaysCheckboxes from "@/features/recurrencePattern/RecurrencePatternWeekdaysCheckboxes.vue";

// other
import useRecurrencePattern, { RecurrencePatternFormInput } from "./useRecurrencePattern";

export default defineComponent({
  name: "DemandFormInput",
  components: { RecurrencePatternWeekdaysCheckboxes },
  props: {
    recurrencePattern: { type: Object as PropType<RecurrencePatternFormInput>, required: true }
  },
  emits: ["pattern-selected"],
  setup(props, context) {
    const { createRecurrencePatternFormInput } = useRecurrencePattern();

    const recurrencePatternInput = createRecurrencePatternFormInput({ ...props.recurrencePattern });

    const onSelectWeekdays = (weekdays: number[]) => {
      recurrencePatternInput.byweekday = weekdays;
      context.emit("pattern-selected", recurrencePatternInput);
    };

    return {
      recurrencePatternInput,
      onSelectWeekdays
    };
  }
});
