
import { defineComponent, PropType } from "vue";
// components
import DemandTemplateDeleteButton from "@/features/demandTemplates/DemandTemplateDeleteButton.vue";
import TableRowMenu from "@/components/table/TableRowMenu.vue";
import TableRowMenuItem from "@/components/table/TableRowMenuItem.vue";
// other
import { DemandTemplate } from "@/graphql/types";
import { cleanUuid } from "@/utils/globalHelpers";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "DemandTemplateTableItemMenu",
  components: {
    DemandTemplateDeleteButton,
    TableRowMenu,
    TableRowMenuItem
  },
  props: {
    demandTemplate: { type: Object as PropType<DemandTemplate>, required: true }
  },
  setup(props) {
    // navigation
    const router = useRouter();

    // click edit
    const onEdit = () => {
      const demandTemplateId = cleanUuid(props.demandTemplate.id);
      const workAreaId = cleanUuid(props.demandTemplate.work_area_id);

      router.push({
        name: "demandTemplateDetails",
        params: { workAreaId: workAreaId, demandTemplateId: demandTemplateId }
      });
    };

    return { onEdit };
  }
});
