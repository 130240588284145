
import { defineComponent, ref, watch } from "vue";
import { createRandomPrefixedId } from "@/utils/globalHelpers";

export default defineComponent({
  name: "NumberStepper",
  props: {
    label: {
      type: String,
      default: ""
    },
    initialValue: {
      type: Number,
      default: 0
    },
    minValue: {
      type: Number,
      default: -99
    },
    maxValue: {
      type: Number,
      default: 99
    },
    excludedValues: {
      type: Array as () => number[],
      default: () => []
    }
  },
  emits: ["number-selected"],
  setup(props, context) {
    const stepperValue = ref(props.initialValue);
    const stepperId = createRandomPrefixedId("numberStepper");

    // check if min or max values of stepper are in exclude list
    if (props.excludedValues.includes(props.maxValue) || props.excludedValues.includes(props.minValue)) {
      console.error("Min or max values of number stepper must not be excluded!");
    }

    // increment number
    const onIncrement = () => {
      if (stepperValue.value < props.maxValue) {
        stepperValue.value++;
        // check if new value is excluded. if so, increment again
        if (props.excludedValues.includes(stepperValue.value)) onIncrement();
      }
    };

    // decrement number
    const onDecrement = () => {
      if (stepperValue.value > props.minValue) {
        stepperValue.value--;
        // check if new value is excluded. if so, decrement again
        if (props.excludedValues.includes(stepperValue.value)) onDecrement();
      }
    };

    // reset stepper to initial value, used for click outside
    const resetStepper = () => {
      if (typeof stepperValue.value === "string" && stepperValue.value === "") stepperValue.value = props.initialValue;
    };

    // handle input strings
    watch(stepperValue, curr => {
      // ignore empty strings
      if (typeof curr === "string" && curr === "") return;
      if (typeof curr === "string") {
        // handle input of strings
        // convert to number
        const input = parseInt(curr);
        // if input is number, set check range, otherwise set to initial value
        if (isNaN(input) === false) {
          // check max and min values
          if (stepperValue.value <= props.maxValue && stepperValue.value >= props.minValue) stepperValue.value = input;
          else stepperValue.value = props.initialValue;
          // set to initial value if no number is entered
        } else stepperValue.value = props.initialValue;
      }
      // emit selected number
      context.emit("number-selected", stepperValue.value);
    });

    const buttonClasses =
      "w-8 inline-flex items-center py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 active:bg-gray-100 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500";

    return {
      stepperValue,
      resetStepper,
      onIncrement,
      onDecrement,
      stepperId,
      buttonClasses
    };
  }
});
