import { computed, reactive, watch } from "vue";
import { findIndexStartsWith, filterArrayStartsWith, ArrayItem } from "@/utils/arrayHelpers";
import { scrollYIntoViewIfNeeded } from "@/utils/globalHelpers";

export type SelectMenuItem = ArrayItem;

// Type of gantt state
interface SelectMenuState {
  menuVisible: boolean;
  selected: SelectMenuItem | null | undefined;
  highlighted: SelectMenuItem | null | undefined;
  options: SelectMenuItem[];
  optionsFiltered: SelectMenuItem[];
  searchString: string;
  lastKeyValue: string;
  // lastKeyVTime: Date;
  selectMenuInputValue: string;
}

function findSelectMenuItemByValue(itemList: SelectMenuItem[], searchString: string, regexList?: string[]) {
  const searchArray = itemList.map(item => item.value);
  const index = findIndexStartsWith(searchArray, searchString, regexList);
  if (index === -1) return null;
  else return itemList[index];
}

function filterOptionsByValue(itemList: SelectMenuItem[], searchString: string, regexList?: string[]) {
  const searchArray = itemList.map(item => item.value);
  const resultIndexList = filterArrayStartsWith(searchArray, searchString, regexList);
  return resultIndexList.map(i => itemList[i]); // return filtered item objects
}

/**
 * State
 */
export default function useSelectMenu(
  options: SelectMenuItem[],
  selectedDefault: SelectMenuItem,
  menuListId: string,
  selectMenuVariant: "button" | "time",
  context: any
) {
  // Initialize gantt state
  const state: SelectMenuState = reactive({
    menuVisible: false,
    selected: selectedDefault,
    highlighted: selectedDefault,
    options: options,
    optionsFiltered: options,
    searchString: "",
    lastKeyValue: "",
    // lastKeyVTime: Date.now();
    selectMenuInputValue: selectedDefault.value
  });
  let isMouseMoving = false;
  let isInputSearchActive = true; // flag for avoiding glitch after setting search value after select

  /**
   * Helper functions
   */

  // emit select event with item
  const emitSelected = () => {
    context.emit("selected", state.selected);
  };

  // select item and set corresponding values
  const selectItem = (item: SelectMenuItem) => {
    isInputSearchActive = false;
    state.selected = item;
    state.highlighted = item;
    state.selectMenuInputValue = item.value;
    emitSelected();
  };

  // reset input value and refocus search input
  const resetselectMenuInputValue = (menuButtonId: string) => {
    state.selectMenuInputValue = "";
    document.getElementById(menuButtonId)?.focus();
  };

  // reset select menu, actions copied from on close
  const resetSelectMenu = () => {
    state.menuVisible = false;
    state.optionsFiltered = options;
    state.options = options;
    state.searchString = "";
    state.lastKeyValue = "";
  };

  // return index of highlighted or selected as fallback
  const getHighlightedIndex = () => {
    if (state.highlighted) return state.options.indexOf(state.highlighted);
    else if (state.selected) return state.options.indexOf(state.selected);
    else return -1;
  };

  const scrollToItem = (itemId: string) => {
    // highlighted element
    const element = document.getElementById(itemId);
    // scroll container
    const container = document.getElementById(menuListId);
    // navigate to
    scrollYIntoViewIfNeeded(element, container);
  };

  function setHighlightedById(itemId: string) {
    // find new highlighted
    const newHighlighted = state.options.find(item => item.id === itemId);
    // scroll to highlighted
    if (newHighlighted) {
      scrollToItem(newHighlighted.id);
      state.highlighted = newHighlighted;
    }
  }

  function clearHighlighted() {
    state.highlighted = null;
  }

  /**
   * Filter options in input mode
   */

  const timeRegexList = [":", "^0"];

  if (selectMenuVariant === "time") {
    watch(
      () => state.selectMenuInputValue,
      value => {
        if (isInputSearchActive === true) {
          // replace special chars
          const replaceChars = [",", ".", "-"];
          value = replaceChars.reduce((acc, char) => acc.replace(char, ":"), value);
          // filter options on input
          state.options = filterOptionsByValue(options, value, timeRegexList);
          // highlight first option
          if (value !== "") state.highlighted = state.options[0];
        }
      }
    );
  }

  // apply input variant specific actions
  const onCloseInputVariant = () => {
    // find closest value to input if menu is closed by click outside
    // query of highlighted prevents loop due to setting highlighted after every select
    if (!state.highlighted) {
      const item = findSelectMenuItemByValue(options, state.selectMenuInputValue, timeRegexList);
      if (item) selectItem(item);
      else selectItem(selectedDefault); // return default if neither an item is highlighted or selected, e.g. if input is infeasable
    } else selectItem(state.highlighted); // set highlighted
  };

  /**
   * Mouse event listeners
   * If it is not checked if mouse is moving, mouseenter and leave are triggered on scroll event with key input
   */

  // set flag that mouse is moving for mouse enter and leave functions
  const mousemove = () => {
    isMouseMoving = true;
  };

  const onMouseEnter = (itemId: string) => {
    if (isMouseMoving === true) {
      setHighlightedById(itemId);
    }
  };

  const onMouseLeave = () => {
    if (isMouseMoving === true) {
      clearHighlighted();
    }
  };

  const addMouseEventListeners = () => {
    document.addEventListener("mousemove", mousemove);
  };

  const removeMouseEventListeners = () => {
    document.removeEventListener("mousemove", mousemove);
  };

  /**
   ********** Keyboard event listeners **********
   */

  /**
   * Search value based on subsequent inputs
   * Used for "invisible" search in select list
   * https://medium.com/javascript-in-plain-english/how-to-detect-a-sequence-of-keystrokes-in-javascript-83ec6ffd8e93
   */
  const onKeyInput = (event: KeyboardEvent) => {
    const charList = "abcdefghijklmnopqrstuvwxyz0123456789";
    const key = event.key.toLowerCase();

    // set mouse flag to prevent events on scrolling into view
    isMouseMoving = false;

    // only consider alphanumeric keys
    if (charList.indexOf(key) === -1) return;

    //   // clear search string after x seconds
    //   const currentTime = Date.now();
    //   if (currentTime - lastKeyTime.value > 1000) {
    //     state.searchString = ""; // reset search string
    //   }
    //   lastKeyTime.value = currentTime; // reset last key time

    //  if a different key is presst than before reset filtered options
    if (state.lastKeyValue !== key) state.optionsFiltered = state.options;

    state.searchString += key; // add key to search string

    // find item based on search string
    let result = findSelectMenuItemByValue(state.optionsFiltered, state.searchString);

    // ##### Retry search if no result is found ######

    // no result but equal key, try again and search only for key
    if (result === null && key === state.lastKeyValue) {
      state.searchString = key;
      result = findSelectMenuItemByValue(state.optionsFiltered, state.searchString);
    }
    //   if no result found, and the search was executed with a filtered options list, reset filtered list and try again with key
    if (result === null && state.optionsFiltered.length !== state.options.length) {
      state.searchString = key;
      state.optionsFiltered = state.options;
      result = findSelectMenuItemByValue(state.optionsFiltered, state.searchString);
    }
    // if still no result is found, try again and search only for key
    if (result === null) {
      state.searchString = key;
      result = findSelectMenuItemByValue(state.optionsFiltered, state.searchString);
    }
    // if at the end no result is found, reset search string and filter and cancel function
    if (result === null) {
      state.optionsFiltered = state.options;
      state.lastKeyValue = key;
      state.searchString = "";
      return;
    }

    // ##### Handle result #####

    // set highlighted value
    setHighlightedById(result.id);
    // remove last result from filtered options, to enable stepwise search when pressing same key multiple times
    state.optionsFiltered = state.optionsFiltered.filter(option => option !== result);
    // update last key value
    state.lastKeyValue = key;
  };

  /**
   *  Keyboard navigation up, down, enter
   */
  const onKeyNav = (event: KeyboardEvent) => {
    // set mouse flag to prevent events on scrolling into view
    isMouseMoving = false;
    // up
    if (event.key === "ArrowUp") {
      const index = getHighlightedIndex();
      if (index !== -1) {
        const highlighted = state.options[Math.max(index - 1, 0)];
        setHighlightedById(highlighted.id);
      }

      // down
    } else if (event.key === "ArrowDown") {
      const index = getHighlightedIndex();
      if (index !== -1) {
        const highlighted = state.options[Math.min(index + 1, state.options.length - 1)];
        setHighlightedById(highlighted.id);
      }

      // enter
    } else if (event.key === "Enter") {
      const index = getHighlightedIndex();
      if (index !== -1) {
        selectItem(state.options[index]);
      } else onCloseInputVariant();
      resetSelectMenu();
      document.removeEventListener("keydown", onKeyNav);
      document.removeEventListener("keydown", onKeyInput);
      // escaoe
    } else if (event.key === "Escape") {
      // apply input variant specific actions
      if (selectMenuVariant !== "button") {
        onCloseInputVariant();
      }
      resetSelectMenu();
      document.removeEventListener("keydown", onKeyNav);
      document.removeEventListener("keydown", onKeyInput);
    }
  };

  const addKeyboardEventListeners = () => {
    document.addEventListener("keydown", onKeyNav);
    // only use keyboard input with button select list
    if (selectMenuVariant === "button") {
      document.addEventListener("keydown", onKeyInput);
    }
  };

  const removeKeyboardEventListeners = () => {
    document.removeEventListener("keydown", onKeyNav);
    document.removeEventListener("keydown", onKeyInput);
  };

  const addEventListeners = () => {
    addKeyboardEventListeners();
    addMouseEventListeners();
  };

  const removeEventListeners = () => {
    removeKeyboardEventListeners();
    removeMouseEventListeners();
  };

  /**
   * Select menu actions
   */

  function isHighlighted(itemId: string) {
    if (state.highlighted) return state.highlighted.id === itemId;
    else return false;
  }

  function isSelected(itemId: string) {
    if (state.selected) return state.selected.id === itemId;
    else return false;
  }

  const onOpenMenu = () => {
    if (state.menuVisible === false) {
      state.options = options;
      state.highlighted = state.selected;
      isInputSearchActive = true;
      state.menuVisible = true;
      // scroll to selected item, delay to wait until element is rendered
      if (state.selected) {
        setTimeout(function() {
          if (state.selected) scrollToItem(state.selected.id);
        }, 1);
      }
      addEventListeners();
    }
  };

  const onCloseMenu = () => {
    if (state.menuVisible === true) {
      // apply input variant specific actions
      if (selectMenuVariant !== "button") {
        onCloseInputVariant();
      }
      // reset search menu
      resetSelectMenu();
      removeEventListeners();
    }
  };

  const onSelectItem = (item: SelectMenuItem) => {
    // only actively select and emit the selected item with the button variant
    // for the input value / time variant, the close function onCloseInputVariant emits the value
    if (selectMenuVariant === "button") {
      selectItem(item);
    }
    onCloseMenu();
  };

  const onToggleMenu = () => {
    if (state.menuVisible === true) onCloseMenu();
    else onOpenMenu();
  };

  return {
    state,
    setHighlightedById,
    clearHighlighted,
    isHighlighted,
    isSelected,
    onSelectItem,
    onToggleMenu,
    onOpenMenu,
    onCloseMenu,
    onMouseEnter,
    onMouseLeave,
    resetselectMenuInputValue,
    selected: computed(() => state.selected),
    menuVisible: computed(() => state.menuVisible),
    removeEventListeners
  };
}
