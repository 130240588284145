
import { defineComponent, onMounted, ref, watch } from "vue";
import { createRandomPrefixedId } from "@/utils/globalHelpers";
import TableRowMenuButton from "./TableRowMenuButton.vue";
import { createPopper, Instance } from "@popperjs/core";

export default defineComponent({
  name: "EmployeeTableItemMenu",
  components: { TableRowMenuButton },
  props: {},
  setup() {
    // menu visibility
    const menuVisible = ref(false);
    const onCloseMenu = () => {
      menuVisible.value = false;
    };

    // id for click outside directive
    const menuId = createRandomPrefixedId("tableRowMenu");
    const menuButtonId = createRandomPrefixedId("menuButtonId");
    const menuListId = createRandomPrefixedId("menuListId");

    // create  popover for select menu item list
    const createSelectMenuPopover = () => {
      const button = document.getElementById(menuButtonId);
      const list = document.getElementById(menuListId);
      if (button && list) {
        const popperInstance = createPopper(button, list, {
          placement: "left",
          strategy: "absolute",
          modifiers: [
            {
              name: "flip",
              enabled: true
            },
            {
              name: "offset",
              options: {
                offset: [25, 6]
              }
            }
          ]
        });
        return popperInstance;
      }
    };

    onMounted(() => {
      // BUG1: if popper instance is created on mounted (and menu not visible), the menu is renedered with a little offset
      // BUG2: if popper instance is created on mounted and when first toggled visible, the menu is rendered somewhere als outside the view
      // it only jumps back after first scroll move.
      // SOLUTION: Create and Destroy popper instance for each toggle
      let popperInstance: Instance | undefined = undefined;
      watch(menuVisible, curr => {
        if (curr === true) popperInstance = createSelectMenuPopover();
        else popperInstance?.destroy();
      });
    });

    return { menuVisible, onCloseMenu, menuId, menuButtonId, menuListId };
  }
});
