<template>
  <button
    type="button"
    class="w-8 h-8 inline-flex items-center justify-center border border-transparent text-gray-400 rounded-full hover:text-gray-500 hover:border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
  >
    <!-- Heroicon name: dots-vertical -->
    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
      <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
    </svg>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "EmployeeTableItemMenu",
  components: {},
  props: {},
  setup() {
    return {};
  }
});
</script>
