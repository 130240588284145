<template>
  <transition
    enter-active-class="ease-out duration-100"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="ease-in duration-100"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div id="formModal" v-if="visible" class="fixed z-20 inset-0">
      <div
        class="flex h-full overflow-hidden items-end justify-center pt-4 px-4 pb-4 text-center sm:items-center lg:px-16 sm:px-6 sm:py-16"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <transition
          enter-active-class="ease-out duration-300"
          enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to-class="opacity-100 translate-y-0 sm:scale-100"
          leave-active-class="ease-in duration-200"
          leave-from-class="opacity-100 translate-y-0 sm:scale-100"
          leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            :id="modalId"
            v-show="visible"
            :class="[sizeClasses, fullHeigth ? 'h-full' : 'max-h-full']"
            class="flex sm:max-w-sm flex-col align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:p-6"
            aria-modal="true"
            v-click-outside="{
              excludedIds: [modalId],
              handler: onClickOutside
            }"
          >
            <CloseButton :autoHide="false" @click="onClose" />
            <!-- Modal content -->
            <div class="flex flex-col h-full overflow-hidden mt-3 sm:mt-0 sm:ml-4">
              <h3 class="text-lg leading-6 font-medium text-gray-900 text-center sm:text-left">
                <slot name="header">
                  {{ header }}
                </slot>
              </h3>
              <div class="flex overflow-auto mt-4">
                <slot name="content" :visible="visible"></slot>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
// components
import CloseButton from "../buttons/CloseButton.vue";
// other
import { createRandomPrefixedId } from "@/utils/globalHelpers";

export default defineComponent({
  components: { CloseButton },
  name: "FormModal",
  props: {
    header: {
      type: String
    },
    size: {
      type: String,
      default: "lg",
      validator: (prop: string) => ["sm", "lg", "xl", "2xl", "3xl", "4xl", "5xl", "6xl", "7xl", "full"].includes(prop)
    },
    fullHeigth: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      required: true
    },
    clickOutside: {
      type: Boolean,
      required: false
    }
  },
  emits: {
    close: null
  },
  setup(props, context) {
    // id of modal background and form
    const modalId = createRandomPrefixedId("formModal");

    const onClose = () => {
      context.emit("close");
    };

    const onClickOutside = () => {
      if (props.clickOutside == true) context.emit("close");
    };

    const sizeClasses = computed(() => `sm:max-w-${props.size}`);

    return {
      onClose,
      onClickOutside,
      sizeClasses,
      modalId
    };
  }
});
</script>
