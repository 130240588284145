<template>
  <div class="grid hover:bg-gray-50 border-b border-gray-100">
    <TableRowItem
      v-if="selectable"
      class="col-span-4 md:col-span-2 cursor-pointer"
      :firstItem="selectable"
      @click="onSelect"
    >
      <div class="flex h-full items-center justify-center">
        <input
          type="checkbox"
          :name="inputId"
          :value="inputId"
          :checked="selected"
          class="h-4 w-4 cursor-pointer text-blue-500 border-gray-300 focus:ring-blue-500"
        />
      </div>
    </TableRowItem>

    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// components
import TableRowItem from "./TableRowItem.vue";
// other
import { createRandomPrefixedId } from "@/utils/globalHelpers";

export default defineComponent({
  name: "TableRow",
  components: { TableRowItem },
  props: { selectable: { type: Boolean, default: false }, selected: { type: Boolean, default: false } },
  emits: { selected: null },
  setup(props, context) {
    const inputId = createRandomPrefixedId("tableRow");
    const onSelect = () => context.emit("selected");
    return { inputId, onSelect };
  }
});
</script>
