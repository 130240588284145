
import { defineComponent, ref } from "vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BaseCheckbox from "@/components/base/BaseCheckbox.vue";
import { RecurrencePatternWeekDay } from "./useRecurrencePattern";
import { getWeekdayByNumber } from "@/utils/dateHelpers";

export default defineComponent({
  name: "RecurrencePatternButtonGroup",
  components: {
    BaseLabel,
    BaseCheckbox
  },
  props: {
    byweekday: { type: Array as () => number[], default: () => [] },
    includeWeekend: {
      type: Boolean,
      default: true
    }
  },
  emits: ["weekdays-selected"],
  setup(props, context) {
    // Initialize weekdays
    const weekdays = ref<RecurrencePatternWeekDay[]>([
      { id: 1, selected: false }, // mon
      { id: 2, selected: false }, // tue
      { id: 3, selected: false }, // wed
      { id: 4, selected: false }, // thu
      { id: 5, selected: false }, // fri
      { id: 6, selected: false }, // sat
      { id: 7, selected: false } //sun
    ]);

    // remove weekend
    if (props.includeWeekend === false) {
      weekdays.value.splice(-2, 2);
    }

    // initialize selected days
    weekdays.value.forEach(day => {
      if (props.byweekday.includes(day.id)) day.selected = true;
    });

    // toggle selected day and emit selected pattern
    const onToggleDay = (day: RecurrencePatternWeekDay) => {
      // check if at least one day is selected, if not, undo toggling
      const selectedList = weekdays.value.map(x => x.selected);
      if (selectedList.every(value => value === false)) {
        day.selected = !day.selected;
      }
      // convert weekdays array to byweekday array
      const byweekday: number[] = [];
      weekdays.value.forEach(day => {
        if (day.selected === true) byweekday.push(day.id);
      });
      // emit days
      context.emit("weekdays-selected", byweekday);
    };

    return { onToggleDay, weekdays, getWeekdayByNumber };
  }
});
