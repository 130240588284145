
import { defineComponent, PropType, computed } from "vue";
// components
import DemandTemplateTableRowMenu from "./DemandTemplateTableRowMenu.vue";
import TableRow from "@/components/table/TableRow.vue";
import TableRowItem from "@/components/table/TableRowItem.vue";
import DemandChart from "@/features/demand/DemandChart.vue";
// other
import { DemandTemplate } from "@/graphql/types";
import { useRouter } from "vue-router";
import { cleanUuid } from "@/utils/globalHelpers";
import useDemand, { createDemandFormInputFromDemandParent } from "@/features/demand/useDemand";
import { recurrencePatternToText } from "@/features/recurrencePattern/useRecurrencePattern";

export default defineComponent({
  name: "DemandTemplateTableRow",
  components: {
    DemandTemplateTableRowMenu,
    TableRow,
    TableRowItem,
    DemandChart
  },
  props: {
    demandTemplate: { type: Object as PropType<DemandTemplate>, required: true },
    selectable: { type: Boolean, default: false },
    selected: { type: Boolean, default: false }
  },
  setup(props, context) {
    const router = useRouter();

    const onShowDetails = () => {
      const demandTemplateId = cleanUuid(props.demandTemplate.id);
      const workAreaId = cleanUuid(props.demandTemplate.work_area_id);

      router.push({
        name: "demandTemplateDetails",
        params: { workAreaId: workAreaId, demandTemplateId: demandTemplateId }
      });
    };

    /**
     * Demand array for chart
     */
    const { timeIntervals, createDemandArrayFromDemandFormInputList } = useDemand();
    const demandArray = computed(() => {
      // create convert demand parents to form input
      const demandFormInputList = props.demandTemplate.demand_parents.map(demand => {
        return createDemandFormInputFromDemandParent(demand);
      });
      // create demand array
      return createDemandArrayFromDemandFormInputList(demandFormInputList);
    });

    // click details or demand field
    const onClick = () => {
      if (props.selectable) context.emit("selected", props.demandTemplate.id);
      else onShowDetails();
    };

    return { onClick, recurrencePatternToText, timeIntervals, demandArray };
  }
});
