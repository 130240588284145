import { Ref } from "vue";
import { v4 as uuidv4 } from "uuid";

/**
 * Create new array as sum of indices given reactive arrays
 * Arrays must have same length
 */
export function addReactiveArrays(arrayList: Ref<Ref<number[]>[]>) {
  // check if arrays have equal length
  arrayList.value.map(array => {
    if (array.value.length !== arrayList.value[0].value.length) {
      console.error("Arrays must have same length!");
    }
  });
  // for each array in arrayList
  let resultArray = arrayList.value[0].value.map((x, idx) =>
    // sum indices of individual arrays
    arrayList.value.reduce((sum, array) => sum + array.value[idx], 0)
  );
  // repair array to be not smaller than 0 at any index
  resultArray = resultArray.map(x => Math.max(0, x));

  return resultArray;
}

/**
 * Create new array as sum of indices given arrays
 * Arrays must have same length
 */
export const addArrays = (arrayList: number[][]) => {
  // check if array list is empty
  if (arrayList.length == 0) return [];
  // check if arrays have equal length
  arrayList.map(array => {
    if (array.length !== arrayList[0].length) {
      console.error("Arrays must have same length!");
    }
  });
  // for each array in arrayList
  const resultArray = arrayList[0].map((x, idx) =>
    // sum indices of individual arrays
    arrayList.reduce((sum, array) => sum + array[idx], 0)
  );
  return resultArray;
};

// remove characters based on multiple regex expressions
export function cleanString(string: string, regexList: string[]) {
  const regexString = regexList.join("|");
  const regex = new RegExp(regexString, "g");
  const cleanedString = string.replace(regex, "");
  return cleanedString;
}

// remove all regex characters from all strings in array
export function cleanStringArray(array: string[], regexList: string[]) {
  const cleanArray = array.map(item => cleanString(item, regexList));
  return cleanArray;
}

// convert all values in array to lower case
export function arrayToLowerCase(array: string[]) {
  const arrayLowerCase = array
    .join("|")
    .toLowerCase()
    .split("|");
  return arrayLowerCase;
}

// convert array to lower case and remove characters
export function prepareArrayForSearch(array: string[], regexList?: string[]) {
  let preparedArray = arrayToLowerCase(array);
  // apply regex if requried
  if (regexList) {
    preparedArray = cleanStringArray(preparedArray, regexList);
  }
  return preparedArray;
}

// convert string to lower case and remove characters
export function prepareSearchValue(searchValue: string, regexList?: string[]) {
  let preparedSearchValue = searchValue.toLowerCase();
  // apply regex if requried
  if (regexList) {
    preparedSearchValue = cleanString(preparedSearchValue, regexList);
  }
  return preparedSearchValue;
}

// return first result that starts with search string
export function findIndexStartsWith(array: string[], searchValue: string, regexList?: string[]) {
  // convert all values to lower case and remove regex chars
  const preparedArray = prepareArrayForSearch(array, regexList);
  const preparedSearchValue = prepareSearchValue(searchValue, regexList);
  // search for index
  const index = preparedArray.findIndex(str => str.startsWith(preparedSearchValue));
  // return result
  return index;
}

// return all INDICES of results that contain search string
export function filterArrayStartsWith(array: string[], searchValue: string, regexList?: string[]) {
  // convert all values to lower case and remove regex chars
  const preparedArray = prepareArrayForSearch(array, regexList);
  const preparedSearchValue = prepareSearchValue(searchValue, regexList);
  // find all indices that match search value
  const indexList = [];
  for (let i = 0; i < preparedArray.length; i++) {
    if (preparedArray[i].startsWith(preparedSearchValue)) {
      indexList.push(i);
    }
  }
  // return result
  return indexList;
}

// dummy objects for list items without ids
export interface ArrayItem {
  value: string;
  id: string;
}

// add ids to plain list of strings
export function addIdsToStringArray(array: string[]) {
  return array.map(item => {
    return {
      value: item,
      id: uuidv4()
    } as ArrayItem;
  });
}

// create array with series of numbers
export function createNumberSeriesArray(end: number, start = 0, steps = 1) {
  const array = [];
  for (let i = start; i <= end; i += steps) {
    array.push(i);
  }
  return array;
}

// // return first index with 0 or 99
// export function findIndexOfFirstZero(array: number[]) {
//   const index = array.findIndex(i => i === 0);
//   if (index < 0) return 99;
//   else return index;
// }

// // return last index with 0 or 99
// export function findIndexOfLastZero(array: number[]) {
//   const index = array.lastIndexOf(0);
//   if (index < 0) return 99;
//   else return index;
// }
