
import { defineComponent, ref, computed, PropType } from "vue";
// components
import TimePicker from "@/components/TimePicker.vue";
import NumberStepper from "@/components/NumberStepper.vue";
// other
import { checkTimeBeforeAfter } from "@/utils/dateHelpers";
import useTimeIntervals from "@/utils/useTimeIntervals";
import useDemand, { DemandFormInput } from "./useDemand";

export default defineComponent({
  name: "DemandFormInput",
  components: {
    TimePicker,
    NumberStepper
  },
  props: {
    demand: { type: Object as PropType<DemandFormInput>, required: true },
    displayLabel: { type: Boolean, default: true },
    displayDelete: { type: Boolean, default: false },
    displaySave: { type: Boolean, default: false },
    saveDemandLoading: { type: Boolean, default: false }
  },
  emits: ["altered", "deleted", "saved"],
  setup(props, context) {
    const { startTime, endTime, timeIntervalSize } = useTimeIntervals();

    const { createDemandFormInput } = useDemand();

    const demandInput = ref(createDemandFormInput({ ...props.demand }));
    const showStartTimeError = ref(false);
    const showEndTimeError = ref(false);

    // check if demand input has been altered
    const initialDemand = ref(JSON.parse(JSON.stringify(props.demand)));
    const demandInputAltered = computed(() => {
      // check form attributes
      const startTimeAltered = initialDemand.value.startTime === demandInput.value.startTime;
      const endTimeAltered = initialDemand.value.endTime === demandInput.value.endTime;
      const amountAltered = initialDemand.value.amount === demandInput.value.amount;
      // check if any attribues deviates from initial demand
      const isAltered = [startTimeAltered, endTimeAltered, amountAltered].some(x => x === false);
      return isAltered;
    });

    // select start time
    const onSelectStartTime = (time: string) => {
      // check if start time is before than end time
      demandInput.value.startTime = time;
      if (checkTimeBeforeAfter(time, demandInput.value.endTime, "before")) {
        // remove errors
        showStartTimeError.value = false;
        showEndTimeError.value = false;
        demandInput.value.hasError = false;
        // emit demand
        context.emit("altered", demandInput.value);
      } else {
        // display error
        showStartTimeError.value = true;
        demandInput.value.hasError = true;
      }
    };

    // select end time
    const onSelectEndTime = (time: string) => {
      // check if end time is later than end time
      demandInput.value.endTime = time;
      if (checkTimeBeforeAfter(demandInput.value.endTime, demandInput.value.startTime, "after")) {
        // remove errors
        showEndTimeError.value = false;
        showStartTimeError.value = false;
        demandInput.value.hasError = false;

        // emit demand
        context.emit("altered", demandInput.value);
      } else {
        // display error
        showEndTimeError.value = true;
        demandInput.value.hasError = true;
      }
    };

    // select employee
    const onSelectEmployees = (employeeCount: number) => {
      demandInput.value.amount = employeeCount;
      context.emit("altered", demandInput.value);
    };

    // delete demand
    const onDeleteDemand = () => {
      // emit demand id
      context.emit("deleted", props.demand.id);
    };

    // delete demand
    const onSaveDemand = () => {
      // emit demand id
      context.emit("saved", demandInput.value);
      // reset initial demand
      initialDemand.value = JSON.parse(JSON.stringify(demandInput.value));
    };

    return {
      demandInput,
      demandInputAltered,
      onSelectStartTime,
      onSelectEndTime,
      onSelectEmployees,
      startTime,
      endTime,
      timeIntervalSize,
      showStartTimeError,
      showEndTimeError,
      onDeleteDemand,
      onSaveDemand
    };
  }
});
