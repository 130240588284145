
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseCheckbox",
  props: {
    label: {
      type: String,
      default: ""
    },
    caption: {
      type: String,
      default: ""
    },
    // only used for v-model binding, no actual prop
    modelValue: {
      type: [Boolean],
      required: true
    },
    name: {
      type: String,
      required: true
    },
    labelPosition: {
      type: String,
      validator: (prop: string) => ["right", "bottom"].includes(prop),
      default: "right"
    }
  },
  setup() {
    return {};
  }
});
